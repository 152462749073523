import React, { FC } from 'react';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, components, MultiValue } from 'react-select';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';

import { CustomMenuList, CustomOption } from '../MultiValueSelect.components';
import { selectCustomStylesCreator } from '../MultiValueSelect.jss';
import { ICreatableMultiSelect, OptionType } from '../multiValuesSelect';
import mixpanel from 'mixpanel-browser';
import { EnvsManager } from 'utils/enviroments';

/**
 * Селект с выпадающим списком и инпутом
 * Значения можно добавлять через список и инпут
 */
const MultiSelectWithCreate: FC<ICreatableMultiSelect> = ({
	value,
	options,
	isOptionSelected,
	onOptionSelect,
	onCreateOption,
	onInputChange,
	placeholder,
	height,
	backgroundColor,
	border,
	color,
	onInputBlur,
	disabled,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const styles: any = selectCustomStylesCreator({
		height,
		backgroundColor,
		border,
		color,
		disabled,
	});

	const handleOptionSelect = (newValues: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при выборе опции
			mixpanel.track('Option Selected', {
				selectedOption: newValues?.map((option) => option.label).join(', ') || 'None',
				action: actionMeta.action, // Дополнительно можно отслеживать действие (например, select-option)
			});
		}

		onOptionSelect(newValues, actionMeta); // Теперь передаем оба аргумента
	};

	const handleCreateOption = (newOption: string) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при создании новой опции
			mixpanel.track('Option Created', {
				createdOption: newOption,
			});
		}

		onCreateOption(newOption); // Вызываем оригинальный обработчик
	};

	const handleInputChange = (inputValue: string) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при вводе текста для поиска или создания новой опции
			mixpanel.track('Search or Create Input', {
				inputValue,
			});
		}

		onInputChange(inputValue); // Вызываем оригинальный обработчик
	};

	return (
		<CreatableSelect
			value={value}
			options={options}
			onChange={handleOptionSelect}
			onInputChange={handleInputChange}
			onCreateOption={handleCreateOption}
			styles={styles}
			closeMenuOnSelect={false}
			isMulti
			createOptionPosition="first"
			formatCreateLabel={(str): string => str}
			tabIndex={0}
			isValidNewOption={(): boolean => true}
			hideSelectedOptions={false}
			components={{
				Menu: options.length > 0 ? components.Menu : () => null,
				MenuList: CustomMenuList,
				Option: CustomOption,
				DropdownIndicator: options.length > 0 ? components.DropdownIndicator : null,
			}}
			placeholder={placeholder || translate('all', language)}
			onBlur={onInputBlur}
			isDisabled={disabled}
		/>
	);
};

export default MultiSelectWithCreate;
