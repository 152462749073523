import React, { FC, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { reportsSlice } from 'store';
import { ContainedSelect } from 'components/common';
import { translate } from 'localizations';
import { useReportTranslations } from 'pages/Reports/hooks/useReportTranslations';
import { CheckTypeDataInChart } from 'pages/Reports/types/ReportsTypes';
import { ChartType } from 'store/reports/reports.types';
import ChartBar from 'shared/ui/Charts/ChartBar/ChartBar';
import ChartArea from 'shared/ui/Charts/ChartArea/ChartArea';
import ChartPie from 'shared/ui/Charts/ChartPie/ChartPie';
import ChartRadar from 'shared/ui/Charts/ChartRadar/ChartRadar';
import styles from './style.module.scss';

interface ChartsBlockPropsType {
	chartTypeOptions: {
		label: string;
		value: ChartType;
	}[];
	tableRows: any[];
	dataChart: [];
	handleCheckChart: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleSetChartType: (event: any) => void;
	checkChart: CheckTypeDataInChart;
	dataChartLegend: string[];
	dataLegendLabelX: string;
	language: string;
	checkboxShowChart: boolean;
	chartType: { value: string; label: string };
	checkboxesValues: CheckTypeDataInChart[];
}

const ChartsBlock: FC<ChartsBlockPropsType> = React.memo(
	({
		chartTypeOptions,
		tableRows,
		dataChart,
		handleCheckChart,
		handleSetChartType,
		checkChart,
		dataChartLegend,
		dataLegendLabelX,
		language,
		checkboxShowChart,
		chartType,
		checkboxesValues,
	}) => {
		const dispatch = useDispatch();

		const WIDTH = 800;
		const HEIGHT = 320;
		// const HEIGHT2 = 350;

		useEffect(() => {
			const translateChartType: { value: ChartType; label: string } | undefined = chartTypeOptions.find(
				(item) => item.value === chartType.value,
			);
			if (translateChartType) dispatch(reportsSlice.actions.setChartType(translateChartType));
		}, [language]);

		const { translatedText } = useReportTranslations();

		return (
			<div className={styles.chartFlex}>
				<div className={styles.charts}>
					{chartType.value === 'histogram' && (
						<div className={styles.chart}>
							<ChartBar
								data={tableRows}
								width={WIDTH}
								height={HEIGHT}
								dataKey={dataChart}
								checkChart={checkChart}
								dataChartLegend={dataChartLegend}
								dataLegendLabelX={dataLegendLabelX}
							/>
						</div>
					)}
					{chartType.value === 'line_chart' && (
						<div className={styles.chart}>
							<ChartArea
								data={tableRows}
								width={WIDTH}
								height={HEIGHT}
								dataKey={dataChart}
								checkChart={checkChart}
								dataChartLegend={dataChartLegend}
								dataLegendLabelX={dataLegendLabelX}
							/>
						</div>
					)}

					{/* {chartType.value === 'pieChart' && (
						<div className={styles.chartWidth}>
							<ChartPie
								data={tableRows}
								checkChart={checkChart}
								width={WIDTH}
								height={HEIGHT2}
								dataKey={dataChartAll}
							/>
						</div>
					)}
					{chartType.value === 'radarChart' && (
						<div className={styles.chartWidth}>
							<ChartRadar
								data={tableRows}
								checkChart={checkChart}
								width={WIDTH}
								height={HEIGHT2}
								dataKey={dataChartAll}
							/>
						</div>
					)} */}
				</div>
				<div className={styles.radiosBlock}>
					<FormGroup>
						<FormControl>
							<FormLabel id="radio-buttons-group" className={styles.radioButton}>
								{translate('reportShow', language)}
							</FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-group"
								defaultValue="calls"
								name="radio-buttons-group"
								onChange={handleCheckChart}
								value={checkChart}
							>
								{checkboxesValues.map((checkbox: CheckTypeDataInChart) => (
									<FormControlLabel
										value={checkbox}
										control={<Radio />}
										label={translatedText[checkbox]}
									/>
								))}
							</RadioGroup>
						</FormControl>
					</FormGroup>

					<FormGroup className={styles.formGroup}>
						<FormControl>
							<FormLabel id="radio-buttons-group" className={styles.radioLabel}>
								{translate('reportTypeGraph', language)}
							</FormLabel>
							{checkboxShowChart && (
								<div>
									<ContainedSelect
										height="38px"
										width="265px"
										justify="flex-start"
										onSelectChange={handleSetChartType}
										options={chartTypeOptions}
										value={chartType}
									/>
								</div>
							)}
						</FormControl>
					</FormGroup>
				</div>
			</div>
		);
	},
);

export default ChartsBlock;
