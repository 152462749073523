import moment from 'moment';
import { translate } from 'localizations';

export const useDateRanges = (language: string, typeRanges: 'all' | 'current') => {
	const ranges = {
		// все периоды
		all: {
			[translate('yesterday', language)]: [
				moment().subtract(1, 'days').startOf('day'),
				moment().subtract(1, 'days').endOf('day'),
			],
			[translate('today', language)]: [moment().startOf('day'), moment().endOf('day')],
			[translate('lastWeek', language)]: [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
			[translate('lastMonth', language)]: [
				moment().subtract(1, 'month').add(1, 'day').startOf('day'),
				moment().endOf('day'),
			],
			[translate('lastYear', language)]: [
				moment().subtract(1, 'year').add(1, 'day').startOf('day'),
				moment().endOf('day'),
			],
			[translate('currentWeek', language)]: [moment().startOf('week'), moment().endOf('week')],
			[translate('currentMonth', language)]: [moment().startOf('month'), moment().endOf('month')],
			[translate('currentQuarter', language)]: [moment().startOf('quarter'), moment().endOf('quarter')],
		},
		// периоды без прошлого времени
		// сегодня, текущая неделя, текущий месяц, текущий квартал
		current: {
			[translate('today', language)]: [moment().startOf('day'), moment().endOf('day')],
			[translate('currentWeek', language)]: [moment().startOf('week'), moment().endOf('week')],
			[translate('currentMonth', language)]: [moment().startOf('month'), moment().endOf('month')],
			[translate('currentQuarter', language)]: [moment().startOf('quarter'), moment().endOf('quarter')],
		},
		// текущий месяц, прошлый месяц, текущий квартал
		custom: {
			[translate('currentMonth', language)]: [moment().startOf('month'), moment().endOf('month')],
			[translate('lastMonth', language)]: [
				moment().subtract(1, 'month').startOf('month'),
				moment().subtract(1, 'month').endOf('month'),
			],
			[translate('currentQuarter', language)]: [moment().startOf('quarter'), moment().endOf('quarter')],
		},
	};

	return {
		ranges: ranges[typeRanges],
	};
};
