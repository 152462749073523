/* eslint-disable */
import React, { FC } from 'react';
import Select, { components } from 'react-select';

// SVG BLOCK
const VerticalDotsSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="21" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8.36743 2.68359C7.69851 2.68359 7.15625 3.22588 7.15625 3.89477C7.15625 4.56367 7.69851 5.10595 8.36743 5.10595C9.03635 5.10595 9.57861 4.56367 9.57861 3.89477C9.57861 3.22588 9.03635 2.68359 8.36743 2.68359Z"
			fill="#2F3747"
		/>
		<path
			d="M7.15625 9.00024C7.15625 8.33135 7.69854 7.78906 8.36743 7.78906C9.03632 7.78906 9.57861 8.33135 9.57861 9.00024C9.57861 9.66914 9.03632 10.2114 8.36743 10.2114C7.69854 10.2114 7.15625 9.66914 7.15625 9.00024Z"
			fill="black"
		/>
		<path
			d="M7.15625 14.1057C7.15625 13.4368 7.69854 12.8945 8.36743 12.8945C9.03632 12.8945 9.57861 13.4368 9.57861 14.1057C9.57861 14.7746 9.03632 15.3169 8.36743 15.3169C7.69854 15.3169 7.15625 14.7746 7.15625 14.1057Z"
			fill="black"
		/>
	</svg>
);

const HorizontalDotsSvg = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="20" height="20 " viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M4 7C3.44771 7 3 7.44773 3 8C3 8.55227 3.44771 9 4 9C4.55229 9 5 8.55227 5 8C5 7.44773 4.55229 7 4 7Z"
			fill="black"
		/>
		<path
			d="M7 8C7 7.44773 7.44773 7 8 7C8.55227 7 9 7.44773 9 8C9 8.55227 8.55227 9 8 9C7.44773 9 7 8.55227 7 8Z"
			fill="black"
		/>
		<path
			d="M11 8C11 7.44773 11.4477 7 12 7C12.5523 7 13 7.44773 13 8C13 8.55227 12.5523 9 12 9C11.4477 9 11 8.55227 11 8Z"
			fill="black"
		/>
	</svg>
);

// TYPES BLOCK
type CustomControlSelectPropsType = {
	handleSelectChange: (event: any) => void;
	svg: 'horizontal' | 'vertical' | string;
	options: { value: any; label: any }[];
	optionsPosition: 'top' | 'bottom';
	disabled?: boolean;
	textColor?: string;
};

const CustomControlSelect: FC<CustomControlSelectPropsType> = ({
	disabled,
	handleSelectChange,
	svg,
	options,
	optionsPosition,
	textColor,
}) => {
	// STYLES BLOCK
	const customDotsStyles = {
		container: (base: any) => ({
			...base,
		}),
		menu: (provided: any, state: any) => ({
			...provided,
			width: '230px',
			right: '0',
			bottom: '35px',
			boxShadow: '0px 0px 4px rgba(98, 98, 98, 0.22)',
			borderRadius: '10px',
			cursor: 'pointer',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			backgroundColor: '#ffffff',
			color: '#000',
			zIndex: '100000 !important',
		}),
		option: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			padding: '10px 24px',
			fontFamily: 'Inter, sans-serif',
			fontSize: '14px',
			backgroundColor: '#ffffff',
			color: textColor ?? '#000',
			'&:hover': {
				backgroundColor: '#F8FAFC',
				color: '#722ED1',
			},
		}),
		menuList: (provided: any, state: any) => ({
			...provided,
			padding: '0',
			display: 'flex',
			flexDirection: 'column-reverse',
			boxShadow: '0px 0px 4px rgba(98, 98, 98, 0.22)',
		}),
		control: (provided: any, state: any) => ({
			...provided,
			cursor: 'pointer',
			width: '32px',
			height: '32px',
			maxHeight: '32px',
			minHeight: '32px',
			border: 'none',
			boxShadow: 'none',
			backgroundColor: '#EEF2F6',
			borderRadius: '5px',
			justifyContent: 'center',
			display: 'flex',
		}),
		valueContainer: (provided: any, state: any) => ({
			...provided,
			position: 'absolute',
			zIndex: '1',
		}),
		dropdownIndicator: (provided: any, state: any) => ({
			zIndex: '2000',
		}),
	};

	return (
		<Select
			isDisabled={disabled}
			menuPlacement={optionsPosition}
			styles={customDotsStyles}
			placeholder=""
			isSearchable={false}
			components={{
				DropdownIndicator: () => (
					<>
						{(svg === 'horizontal' && <HorizontalDotsSvg />) ||
							(svg === 'vertical' && <VerticalDotsSvg />) ||
							(svg && <div>{svg}</div>)}
					</>
				),
				IndicatorSeparator: () => null,
				SingleValue: () => null,
			}}
			onChange={handleSelectChange}
			options={options}
		/>
	);
};

export default CustomControlSelect;
