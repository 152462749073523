import { FC } from 'react';
import { CustomSwitch } from 'shared/ui';
import s from './styles.module.scss';

interface ToggleFormItemProps {
	isChecked: boolean;
	label: string;
	onChecked: (e: any) => void;
}

const ToggleFormItem: FC<ToggleFormItemProps> = ({ isChecked, label, onChecked }) => (
	<div className={s.toggleItem}>
		<span>{label}</span>
		<CustomSwitch checked={isChecked} onChecked={onChecked} />
	</div>
);

export default ToggleFormItem;
