import { FC } from 'react';
import { VerticalMenu } from 'components/common';
import { VerticalMenuProps } from '../VerticalMenu/VerticalMenu';

import s from './LeftMenuLayout.module.scss';

interface LeftMenuLayoutProps {
	menuProps: VerticalMenuProps;
}
const LeftMenuLayout: FC<LeftMenuLayoutProps> = (props) => {
	const { children, menuProps } = props;

	return (
		<div className={s.page}>
			<VerticalMenu {...menuProps} />
			<div className={s.content}>{children}</div>
		</div>
	);
};

export default LeftMenuLayout;
