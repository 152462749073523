import React, { FC, memo, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import cn from 'classnames';

type FieldPropsType = {
	label: string | ReactNode | undefined;
	labelBrother?: ReactNode;
	bgColor?: string;
	width?: string;
	height?: string;
	margin?: string;
	padding?: string;
	borderColor?: string | null;
	classNameLabel?: string;
	required?: boolean;
	classNameRoot?: string;
	classNameWrapper?: string;
	testId?: string;
};

const Field: FC<FieldPropsType> = memo(
	({
		children,
		label,
		bgColor,
		width,
		height,
		margin,
		padding,
		labelBrother,
		borderColor,
		classNameLabel,
		classNameRoot,
		classNameWrapper,
		required = false,
		testId,
	}) => {
		const useStyles = makeStyles({
			fieldBox: {
				margin: margin || '0',
				height: height || 'auto',
				width: width || 'auto',
				display: 'flex',
				flexDirection: 'column',
			},
			fieldWrapper: {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'flex-start',
				flex: '1 0 auto',
			},
			fieldText: {
				backgroundColor: bgColor || '#F8FAFC',
				border: borderColor ? `1px solid ${borderColor}` : '1px solid #E3E8EF',
				padding: padding || '3px 10px',
				borderRadius: '5px',
			},
			fieldLabel: {
				cursor: 'default !important',
				color: '#738094 !important',
				fontWeight: '700 !important',
				fontSize: '13px !important',
				marginBottom: '2px',
			},
			fieldRequired: {
				color: 'red',
			},
		});
		const classes = useStyles();
		return (
			<div className={cn(classes.fieldBox, classNameRoot)} data-testid={testId}>
				<div className={cn(classes.fieldWrapper, classNameWrapper)}>
					<Typography className={cn(classes.fieldLabel, classNameLabel)}>
						{label}
						{required && <span className={classes.fieldRequired}>*</span>}
					</Typography>
					{labelBrother}
				</div>
				<div className={classes.fieldText}>{children}</div>
			</div>
		);
	},
);

export default Field;
