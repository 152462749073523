import { FC, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { CriteriaBlockPage, CriteriasType } from 'store/search/search.types';
import cn from 'classnames';
import { translate } from 'localizations';
import { PlusIcon } from 'components/Icons/PlusIcon';
import { useAppSelector } from 'hooks/redux';
import variables from 'styles/variables.scss';
import { UniversalButton } from 'shared/ui';
import { Logic } from '../Selects/MultiValueSelect/components/MultiSelectWithConditions/MultiSelectWithConditions';
import { getStyles } from './Criterias.jss';
import { getMultiSelectStyles } from '../Selects/MultiValueSelect/MultiValueSelect.jss';
import { CustomValueHandler, HandlerOnChange, OnAddCondition, Page } from './CriteriasList';
import CriteriaConditions from './components/CriteriaConditions';
import { getEmptyCriteria } from './utils';

interface CriteriaWithConditions {
	criteriaKey: string;
	data: CriteriasType[];
	allCriterias: CriteriasType[];
	block?: CriteriaBlockPage;
	index: number;
	handlerOnChange?: HandlerOnChange;
	isHandlerFunction?: boolean | undefined;
	page: Page;
	onAddCondition?: OnAddCondition;
	onDeleteCondition?: OnAddCondition;
	customValueHandler?: CustomValueHandler;
	title?: string;
}

const CriteriaWithConditions: FC<CriteriaWithConditions> = ({
	criteriaKey,
	data,
	allCriterias,
	index,
	handlerOnChange,
	isHandlerFunction,
	page,
	block,
	customValueHandler,
	onAddCondition,
	onDeleteCondition,
	title,
}) => {
	const { language } = useAppSelector((state) => state.lang);
	const { addButton, addButtonTop, criteriaWithConditions, criteriaSingle, criteriaReportsBlock, criteriaMarkup } =
		getStyles();

	const { selectTitleComplex } = getMultiSelectStyles();
	const [hasConditions, setHasConditions] = useState(false);
	const [criteriaItems, setCriteriaItems] = useState<CriteriasType[]>(data);

	const emptyCriteriaItem = getEmptyCriteria({
		criteria: data[0],
		criteriaItems,
		block,
		index,
		handlerOnChange,
		isHandlerFunction,
		page,
		hasConditions,
	});

	useEffect(() => {
		setCriteriaItems(data);
		if (data.length > 1) setHasConditions(true);
	}, [data]);

	const handleAddCondition = () => {
		setHasConditions(true);
		setCriteriaItems((prev) => [...prev, emptyCriteriaItem as CriteriasType]);
		const currentCriteriaFull = allCriterias?.find((item) => item.key === criteriaKey);

		const newCriteria = {
			...(currentCriteriaFull as CriteriasType),
			values: [],
			logic: 'or' as Logic,
			conditionItemId: emptyCriteriaItem.conditionItemId,
		};

		if (onAddCondition) {
			onAddCondition(newCriteria, page, index);
		}
	};

	const handleDelete = (criteria: CriteriasType) => {
		setCriteriaItems((prev) => prev.filter((item) => item.conditionItemId !== criteria.conditionItemId));

		if (onDeleteCondition) {
			onDeleteCondition(criteria, page, index);
		}
	};

	const isSingleCondition = criteriaItems.length === 1 || !hasConditions;

	const testId = `addCondition_${criteriaKey}`;

	return (
		<div
			className={cn(criteriaSingle, {
				[criteriaWithConditions]: !isSingleCondition,
				[criteriaReportsBlock]: page === 'reports_block',
				[criteriaMarkup]: page === 'markup',
			})}
		>
			<Typography className={selectTitleComplex}>{criteriaItems[0].title || title}</Typography>
			<CriteriaConditions
				criteriaItems={criteriaItems}
				allCriterias={allCriterias}
				isSingleCondition={isSingleCondition}
				handlerOnChange={handlerOnChange}
				isHandlerFunction={isHandlerFunction}
				handleDelete={handleDelete}
				page={page}
				block={block}
				customValueHandler={customValueHandler}
			/>
			<UniversalButton
				buttonStyle="plain"
				className={cn(addButton, addButtonTop)}
				text={translate('addCondition', language)}
				icon={<PlusIcon fill={variables.purple_5} />}
				handleClick={handleAddCondition}
				testId={testId}
			/>
		</div>
	);
};

export default CriteriaWithConditions;
