import { FC } from 'react';
import { Link } from 'react-router-dom';
import { UserType } from 'store/user/user.types';
import { translate } from 'localizations';
import { BackIcon } from 'components/Icons/BackIcon';
import { useUserChange } from 'hooks';
import { useAppSelector } from 'hooks/redux';
import { UserSelect } from 'features/UserSelect';
import { UniversalButton } from 'shared/ui';

import s from './AdminBar.module.scss';

const AdminBar: FC<{ user: UserType }> = ({ user }) => {
	const { name, id } = user;
	const { language } = useAppSelector((state) => state.lang);
	const { loggedUser } = useAppSelector((state) => state.user);

	const { handleUserChange } = useUserChange();
	const handleReturnToUser = async () => {
		await handleUserChange({
			value: loggedUser?.id,
			label: loggedUser?.id || '',
		});
	};

	return (
		<div className={s.root} data-testid="adminBar">
			<div className={s.root__name}>{name}</div>
			<UserSelect
				mode="SelectForHeader"
				onUserSelect={handleUserChange}
				isSearchable
				width="200px"
				height="26px"
			/>
			<UniversalButton
				buttonStyle="plain"
				text=""
				data-testid="backToLoggedUser"
				icon={<BackIcon />}
				handleClick={handleReturnToUser}
				className={s.root__backBtn}
			/>
			<Link data-testid="userLink" className={s.root__users} to={`/${language}/${id}/users`}>
				{translate('users', language)}
			</Link>
		</div>
	);
};

export default AdminBar;
