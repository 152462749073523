import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	headerWrapper: {
		backgroundColor: '#FFFFFF',
	},
	headerInner: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		maxWidth: '1920px',
		height: '100%',
		width: '100%',
		margin: '0 auto',
		minHeight: '45px',
	},
	headerLeftBlock: {
		display: 'flex',
		alignItems: 'center',
	},
	headerIconWrapper: {
		maxWidth: '73px',
		width: '73px',
		margin: '0 24px 4px 24px',
	},
	headerIcon: {
		display: 'block',
		maxWidth: '100%',
		width: '100%',
		height: 'auto',
	},

	headerRightBlock: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	headerLogout: {
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		padding: '0 17px 0 26px !important',
		// @ts-ignore
		textTransform: 'none !important',
		color: '#738094 !important',
		'&:hover': {
			backgroundColor: '#fff !important',
		},
		'& .MuiButton-startIcon': {
			marginRight: '10px',
		},
		'& .MuiButton-startIcon svg': {
			fill: '#738094',
		},
	},
});
