import { FC } from 'react';
import { Typography } from '@mui/material';
import { TemplateType } from 'store/search/search.types';
import variables from 'styles/variables.scss';
import { ValueObj } from 'utils/optionsCreator';
import TextSelect from '../Selects/TextSelectOLD/TextSelect';
import { useStyles } from './styles/CriteriaTemplate.jss';
import { useAppSelector } from 'hooks/redux';
import mixpanel from 'mixpanel-browser';
import { EnvsManager } from 'utils/enviroments';

interface CriteriaTemplateProps {
	title: string;
	handleValueChange: (event: ValueObj) => void;
	options: { value: string | TemplateType; label: string }[];
	currentTemplate: { value: string | TemplateType; label: string } | null;
	allTemplates: ValueObj[] | TemplateType[];
	name?: string;
	height?: string;
	onClearTemplateClick?: () => void;
	dataTestTd: string;
}

const CriteriaTemplate: FC<CriteriaTemplateProps> = ({
	title,
	handleValueChange,
	options,
	currentTemplate,
	allTemplates,
	name,
	height,
	onClearTemplateClick,
	dataTestTd,
}) => {
	const classes = useStyles();
	const { loggedUser } = useAppSelector((state) => state.user);

	const handleSelectChange = (event: ValueObj) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при выборе опции
			mixpanel.track('Template Selected', {
				selectedTemplate: event.label,
				userName: loggedUser?.name || 'Unknown User',
			});
		}

		handleValueChange(event);
	};

	return (
		<div className={classes.criteriaTemplateTitle}>
			<TextSelect
				name={name || 'templatesSelect'}
				value={currentTemplate || null}
				handleValueChange={handleSelectChange}
				options={options}
				iconPosition="right"
				customControl={
					<div className={classes.criteriaTemplateText} data-testid={dataTestTd}>
						{currentTemplate && currentTemplate.label ? (
							<>
								<Typography className={classes.criteriaTemplateTitleT}>
									{currentTemplate.label}
								</Typography>
								<span className={classes.criteriaTemplateLength}>({allTemplates.length})</span>
							</>
						) : (
							<>
								<Typography className={classes.criteriaTemplateTextNumber}>{title}</Typography>
								<span className={classes.criteriaTemplateLength}>({allTemplates.length})</span>
							</>
						)}
					</div>
				}
				ifArrowColor={currentTemplate ? variables.purple_5 : variables.purple_5}
				menuPosition="left"
				height={height}
				onClearTemplateClick={onClearTemplateClick}
			/>
		</div>
	);
};

export default CriteriaTemplate;
