import React, { FC } from 'react';
import cn from 'classnames';
import './style.css';

type TypographyMain = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';

type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subheading' | 'body1' | 'body2';

const variantsMapping: { [key: string]: TypographyMain } = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',

	subheading: 'h6',
	body1: 'p',
	body2: 'p',
};

interface TypographyTextProps {
	variant: TypographyVariant;
	weight?: number;
	children: React.ReactNode;
}
const TypographyText: FC<TypographyTextProps> = ({ variant, weight, children, ...props }) => {
	const varMap: TypographyMain = variantsMapping[variant];
	const Component = variant ? varMap : 'p';

	return (
		<Component
			className={cn({
				[`typography--variant-${variant}`]: variant,
				[`typography--weight-${weight}`]: weight,
			})}
			{...props}
		>
			{children}
		</Component>
	);
};

export default TypographyText;
