/* eslint-disable */
import { FC, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { ClickAwayListener } from '@mui/material';

export type TooltipForMenuPropsType = {
	children: any;
	title: string | ReactNode;
	open: boolean;
	handleTooltipClose: () => void;
	placement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';
	followCursor?: boolean;
	isInteractive?: boolean;
	popperOffset?: [number, number]; // смещение тултипа относительно родителя
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		fontFamily: 'Inter, sans-serif !important',
		color: '#2F3747 !important',
		backgroundColor: 'white !important',
		width: '247px !important',
		fontSize: '14px !important',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '22px !important',
		padding: '8px 12px !important',
		borderRadius: '8px !important',
		boxShadow:
			'0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
	},
	[`& .${tooltipClasses.tooltip} span`]: {
		backgroundColor: 'transparent !important',
		display: 'block',
		height: '0.71em',
		padding: 0,
	},

	[`& .${tooltipClasses.arrow}`]: {
		color: '#fff !important',
	},
});

const TooltipForMenu: FC<TooltipForMenuPropsType> = ({
	children,
	title,
	placement,
	followCursor,
	open,
	handleTooltipClose,
	popperOffset,
	isInteractive = false,
}) => {
	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<div>
				<CustomWidthTooltip
					PopperProps={{
						disablePortal: true,
					}}
					onClose={handleTooltipClose}
					open={open}
					disableFocusListener
					disableHoverListener
					disableTouchListener
					arrow
					followCursor={Boolean(followCursor)}
					disableInteractive={!isInteractive}
					title={title}
					placement={placement || 'top'}
					slotProps={
						popperOffset && {
							popper: {
								modifiers: [
									{
										name: 'offset',
										options: {
											offset: popperOffset,
										},
									},
								],
							},
						}
					}
				>
					<div>{children}</div>
				</CustomWidthTooltip>
			</div>
		</ClickAwayListener>
	);
};

export default TooltipForMenu;
