/* eslint-disable indent */
// @ts-nocheck

import { FC } from 'react';
import 'antd/dist/antd.css';
import { DatePicker, Space, ConfigProvider } from 'antd';
import moment, { Moment } from 'moment';
import localeRu from 'antd/es/locale/ru_RU';
import localeEn from 'antd/es/locale/en_US';
import 'moment/locale/ru';
import 'antd/es/date-picker/style/index';
import { useAppSelector } from 'hooks/redux';
import { useStyles } from './DateRangePickerWrapperStyles.jss';
import { useDateRanges } from './hooks/useDateRanges';

interface getLocaleProps {
	[key: string]: any;
}

const START_DATE_LIMIT = '2021-01-01';

interface DateRangePickerWrapperProps {
	value: [Moment, Moment] | null | undefined;
	dateError: string;
	disabled: boolean;
	disabledDate?: any;
	onChange: (dates: any) => void;
	showTime?: boolean;
	hideRanges?: boolean;
	typeRanges?: 'all' | 'current' | 'custom';
}

const DateRangePickerWrapper: FC<DateRangePickerWrapperProps> = ({
	value,
	dateError,
	disabled,
	disabledDate,
	onChange,
	showTime,
	hideRanges,
	typeRanges = 'all',
}) => {
	const { RangePicker } = DatePicker;
	const getLocale: getLocaleProps = {
		ru: localeRu,
		en: localeEn,
	};

	const classes = useStyles({ showTime });
	const { language } = useAppSelector((state) => state.lang);

	const dateFormat = showTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
	const dateFormatList = [dateFormat, 'DD/MM/YYYY'];

	const { ranges } = useDateRanges(language, typeRanges);

	const disabledDateCustom = (current: Moment): boolean =>
		// Can not select days after today (current > moment().endOf('day')) and before date
		current < moment(START_DATE_LIMIT, 'DD/MM/YYYY');
	const getDates = (dates: Date[] | null | null[], shouldShowTime: boolean): [Moment, Moment] | [null, null] => {
		const format = shouldShowTime ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
		if (dates) {
			if (dates[0] && dates[1]) return [moment(dates[0], format), moment(dates[1], format)];
		}
		return [null, null];
	};

	let dateRanges;
	if (hideRanges) {
		dateRanges = undefined;
	} else {
		dateRanges = ranges;
	}

	return (
		<ConfigProvider locale={getLocale[language]}>
			<Space direction="horizontal" size={20} className={classes.wrapperRangePicker}>
				<RangePicker
					defaultValue={getDates(value, showTime)}
					value={getDates(value, showTime)}
					format={dateFormatList}
					status={dateError ? 'error' : ''}
					className={classes.rangePicker}
					disabled={disabled}
					disabledDate={disabledDate || disabledDateCustom()}
					popupClassName={classes.dropdown}
					showTime={showTime ? { format: 'HH:mm' } : false}
					ranges={dateRanges}
					onChange={onChange}
				/>
			</Space>
		</ConfigProvider>
	);
};

export default DateRangePickerWrapper;
