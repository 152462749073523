import { FC } from 'react';
import cn from 'classnames';
import { IconButton } from '@mui/material';
import { UniversalInput } from 'shared/ui';
import { SearchSvg } from '../../Icons/SearchSvg';

import s from './SearchInput.module.scss';

interface SearchInputProps {
	value: string;
	label?: string;
	placeholder?: string;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	classNameRoot?: string;
	classNameBtn?: string;
	testId?: string;
}
const SearchInput: FC<SearchInputProps> = ({
	classNameRoot,
	classNameBtn,
	handleChange,
	value,
	label,
	testId,
	placeholder,
}) => (
	<div className={cn(s.searchInput__root, classNameRoot)}>
		<UniversalInput
			testId={testId}
			name="searchString"
			inputStyle="outlined"
			height="35px"
			bcColor="#F8FAFC"
			borderColor="#E3E8EF"
			label={label || ''}
			placeholder={placeholder || ''}
			value={value}
			handleChange={handleChange}
			iconPosition="end"
			icon={
				<div className={cn(s.searchInput__btn, classNameBtn)}>
					<IconButton type="submit">
						<SearchSvg />
					</IconButton>
				</div>
			}
		/>
	</div>
);

export default SearchInput;
