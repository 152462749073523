/* eslint-disable */
import { FC } from 'react';
import isEqual from 'lodash/isEqual';
import Select, { components, MultiValue } from 'react-select';
import { translate } from 'localizations';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';

import { CustomMenuList, CustomOption, MultiValue as CustomMultiValue } from '../MultiValueSelect.components';
import { selectCustomStylesCreator } from '../MultiValueSelect.jss';
import { IMultiSelectFromOptions, OptionType } from '../multiValuesSelect';
import mixpanel from 'mixpanel-browser';
import { EnvsManager } from 'utils/enviroments';

/**
 * Селект с выпадающим списком и инпутом
 * Значения можно добавлять только через список
 * С помощью инпута можно искать значения в списке
 */
const MultiSelectFromOptions: FC<IMultiSelectFromOptions> = ({
	value,
	options,
	isOptionSelected,
	onOptionSelect,
	placeholder,
	valueHandler,
	localValue,
	height,
}) => {
	const { language } = useAppSelector((state: RootState) => state.lang);
	const styles: any = selectCustomStylesCreator({ height });

	const onMenuClose = () => {
		const isEqualValue = isEqual(value, localValue);
		if (!isEqualValue) valueHandler(localValue);
	};

	const handleOptionSelect = (newValues: MultiValue<OptionType>, actionMeta: any) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при выборе опции
			mixpanel.track('Option Selected', {
				selectedOptions: newValues?.map((option) => option.label).join(', ') || 'None',
				action: actionMeta.action, // Тип действия (select-option, deselect-option и т.д.)
			});
		}

		onOptionSelect(newValues, actionMeta); // Передаем аргументы в оригинальный обработчик
	};

	const handleInputChange = (inputValue: string) => {
		if (!EnvsManager.isDev) {
			// Отправляем событие в Mixpanel при вводе текста для поиска
			mixpanel.track('Search Input', {
				searchQuery: inputValue,
			});
		}

		// Возвращаем значение, чтобы `react-select` его использовал
		return inputValue;
	};

	return (
		<Select
			value={value}
			options={options}
			isOptionSelected={isOptionSelected}
			onChange={handleOptionSelect} // Используем обновленный обработчик для выбора опций
			onInputChange={handleInputChange} // Добавляем обработчик для поиска
			styles={styles}
			openMenuOnFocus
			placeholder={placeholder || translate('all', language)}
			components={{
				Menu: options && options.length > 0 ? components.Menu : () => null,
				Option: CustomOption,
				MultiValue: CustomMultiValue,
				MenuList: CustomMenuList,
			}}
			onMenuClose={onMenuClose}
			isClearable
			closeMenuOnSelect={false}
			isMulti
			isSearchable
			hideSelectedOptions={false}
		/>
	);
};

export default MultiSelectFromOptions;
