/* eslint-disable */
import { IconButton } from '@mui/material';
import { Tooltip } from 'components/common';
import { useAppSelector } from 'hooks/redux';
import { RootState } from 'store/store';
import { translate } from 'localizations';
import { OLD_INTERFACE } from 'config';
import { getTokens } from 'utils/tokens';

const MicroSvg = () => (
	<svg viewBox="0 0 24 24" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M19.7344 10.6407C19.7344 10.5376 19.65 10.4532 19.5469 10.4532H18.1406C18.0375 10.4532 17.9531 10.5376 17.9531 10.6407C17.9531 13.929 15.2883 16.5939 12 16.5939C8.71172 16.5939 6.04688 13.929 6.04688 10.6407C6.04688 10.5376 5.9625 10.4532 5.85938 10.4532H4.45312C4.35 10.4532 4.26562 10.5376 4.26562 10.6407C4.26562 14.5947 7.23281 17.8572 11.0625 18.3189V20.7189H7.65703C7.33594 20.7189 7.07812 21.054 7.07812 21.4689V22.3126C7.07812 22.4157 7.14375 22.5001 7.22344 22.5001H16.7766C16.8562 22.5001 16.9219 22.4157 16.9219 22.3126V21.4689C16.9219 21.054 16.6641 20.7189 16.343 20.7189H12.8438V18.3306C16.718 17.9087 19.7344 14.6275 19.7344 10.6407ZM12 14.6251C14.2008 14.6251 15.9844 12.8626 15.9844 10.6876V5.43762C15.9844 3.26262 14.2008 1.50012 12 1.50012C9.79922 1.50012 8.01562 3.26262 8.01562 5.43762V10.6876C8.01562 12.8626 9.79922 14.6251 12 14.6251ZM9.79688 5.43762C9.79688 4.25168 10.7789 3.28137 12 3.28137C13.2211 3.28137 14.2031 4.25168 14.2031 5.43762V10.6876C14.2031 11.8736 13.2211 12.8439 12 12.8439C10.7789 12.8439 9.79688 11.8736 9.79688 10.6876V5.43762Z"
			fill="#A3AEBE"
		/>
	</svg>
);

const OldInterfaceLink = () => {
	const { token } = getTokens();
	const language = useAppSelector((state: RootState) => state.lang.language);
	return (
		<Tooltip title={translate('oldInterface', language)} placement="bottom">
			<IconButton
				size="small"
				onClick={() => window.open(`${OLD_INTERFACE}auth/react_auto_login?authToken=${token}`)}
			>
				<MicroSvg />
			</IconButton>
		</Tooltip>
	);
};

export default OldInterfaceLink;
